import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import classNames from "classnames";
import styles from "./DictionaryFilters.module.scss";

export function DictionaryFilters(props) {
    const [ key, setKey ] = useState(Date.now());
    const [ title, setTitle ] = useState();
    const [ author, setAuthor ] = useState();
    const [ year, setYear ] = useState();
    const [ showFilters, setShowFilters ] = useState(false);

    const inputTitle = useRef();

    function handleFilterToggle(ev) {
        setShowFilters(!ev.value);
    }

    function handleSubmit() {
        let filters = {};

        if (title) filters["title"] = title;
        if (author) filters["author"] = author;
        if (year) filters["year"] = year;

        props.onChange(filters);
    }

    function handleReset() {
        setKey(Date.now());
        setTitle();
        setAuthor();
        setYear();
        
        props.onChange(undefined);
    }
    
    return <div className={classNames([styles["dictionary__filters"]], "container")} key={key}>
        <Panel toggleable collapsed={!showFilters} onToggle={handleFilterToggle}>
            <div className="row">
                <div className="col-md-4 col-lg-4 mb-3">
                    <span className="p-float-label">
                        <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} ref={inputTitle} />
                        <label htmlFor="title">Obra</label>
                    </span>
                </div>
                <div className="col-md-4 col-lg-4 mb-3">
                    <span className="p-float-label">
                        <InputText id="author" value={author} onChange={(e) => setAuthor(e.target.value)} />
                        <label htmlFor="author">Autor</label>
                    </span>
                </div>
                <div className="col-md-4 col-lg-4 mb-3">
                    <span className="p-float-label">
                        <InputText id="year" value={year} onChange={(e) => setYear(e.target.value)} />
                        <label htmlFor="year">Ano da Publicação</label>
                    </span>
                </div>
            </div>
            <Button type="submit" className={"button gradient-btn mr-3"} disabled={!title && !author && !year} id="search_btn" onClick={handleSubmit}>Pesquisar</Button>
            <Button type="reset" className={"button gradient-btn"} disabled={!title && !author && !year} id="reset_btn" onClick={handleReset}>Limpar</Button>
        </Panel>
    </div>
}