const host = window.location.protocol + "//" + window.location.host;

export const apiroutes = {
    api: `${host}/api/`,
    core: `${host}/api/core`,
    authors: `${host}/api/authors`,
    characters: `${host}/api/characters`,
    contents: `${host}/api/contents`,
    novels: `${host}/api/novels`,
    persons: `${host}/api/persons`,
    admin: `${host}/api/admin`,
}