import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import classNames from "classnames";

function Header() {
    const [ bottomMenu, setBottomMenu ] = useState();
    let history = useHistory();

    useEffect(() => {
        setBottomMenu(history.location.pathname === "/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.pathname]);

    function handleMenuItemClick() {
        document.body.className = "";
        document.getElementsByClassName("side-menu")[0].classList.remove("side-menu-active");
        document.getElementById("close_side_menu").style = null;
    }
    
    return (
        <>
            <header className="site-header" id="header">
                <nav className={classNames("navbar navbar-expand-lg static-nav padding-nav", { "fixed-bottom" : bottomMenu})}>
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src="/images/logo.png" alt="logo" className="logo-default" />
                            <img src="/images/logo.png" alt="logo" className="logo-scrolled" />
                        </a>
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav mx-auto">
                                {menuItems.map((item, idx) => {
                                    if (item.items) {
                                        return (
                                            <li key={idx} className="nav-item dropdown static">
                                                <a className="nav-link dropdown-toggle active" href onClick={(e) => e.preventDefault()} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{item.label}</a>
                                                <ul className="dropdown-menu megamenu">
                                                    <li>
                                                        <div className="container">
                                                            <div className="row">
                                                                {item.items.map((child, childIdx) => {
                                                                    if (child.items) {
                                                                        return <div key={`${idx}-${childIdx}`} className="col-lg-4 col-md-6 col-sm-12">
                                                                            <h5 className="dropdown-title bottom10">{child.label}</h5>
                                                                            {child.items.map((childItem, childItemIdx) => {
                                                                                return <Link key={`${idx}-${childIdx}-${childItemIdx}`} to={childItem.link} className="dropdown-item">{childItem.label}</Link>
                                                                            })}
                                                                        </div>
                                                                    }
                                                                    else {
                                                                        return <div key={`${idx}-${childIdx}`} className="col-lg-4 col-md-6 col-sm-12">
                                                                            {child.isExternal ? 
                                                                                <a href={child.link} className="dropdown-item" rel="noopener noreferrer" target="_blank">{child.label}</a>
                                                                            :
                                                                                <Link to={child.link} className="dropdown-item">{child.label}</Link>
                                                                            }
                                                                        </div>
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        )
                                    }
                                    else {
                                        return <li key={idx} className="nav-item">
                                            <Link to={item.link} className="nav-link">{item.label}</Link>
                                        </li>
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    <a href onClick={(e) => e.preventDefault()} className="d-inline-block d-lg-none sidemenu_btn" id="sidemenu_toggle">
                        <span></span> <span></span> <span></span>
                    </a>
                </nav>
                {/* side menu */}
                <div className="side-menu opacity-0">
                    <div className="overlay"></div>
                    <div className="inner-wrapper">
                        <span className="btn-close btn-close-no-padding" id="btn_sideNavClose"><i></i><i></i></span>
                        <nav className="side-nav w-100">
                            <ul className="navbar-nav">
                                {menuItems.map((item, idx) => {
                                    if (item.items) {
                                        return (
                                            <li key={idx} className="nav-item">
                                                <a className="nav-link collapsePagesSideMenu" data-toggle="collapse" href={idx}>{item.label} <i className="fas fa-chevron-down"></i></a>
                                                <div id={idx} className="collapse sideNavPages">
                                                    <ul className="navbar-nav mt-2">
                                                        {item.items.map((child, childIdx) => {
                                                            if (child.items) {
                                                                return <li key={childIdx} className="nav-item">
                                                                    <a className="nav-link collapsePagesSideMenu" data-toggle="collapse" href={`${idx}-${childIdx}`}>{child.label} <i className="fas fa-chevron-down"></i></a>
                                                                    <div id={`${idx}-${childIdx}`} className="collapse sideNavPages sideNavPagesInner">
                                                                        <ul className="navbar-nav mt-2">
                                                                            {child.items.map((childItem, childItemIdx) => {
                                                                                return <li key={childItemIdx} className="nav-item">
                                                                                    <Link to={childItem.link} className="nav-link">{childItem.label}</Link>
                                                                                </li>
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            }
                                                            else {
                                                                return <li key={childIdx} className="nav-item">
                                                                    <Link to={child.link} className="nav-link" onClick={handleMenuItemClick}>{child.label}</Link>
                                                                </li>
                                                            }
                                                        })}
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                    }
                                    else {
                                        return <li key={idx} className="nav-item">
                                            <Link to={item.link} className="nav-link" onClick={handleMenuItemClick}>{item.label}</Link>
                                        </li>
                                    }
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>
                <div id="close_side_menu" className="tooltip"></div>
                {/* End side menu */}
            </header>
            {/* header */}
            {/*Main Slider*/}
            {/*slider*/}
        </>
    );
}

export default Header;

export const menuItems = [
    { link: "/projeto", label: "Projeto"},
    { link: "/corpo-cientifico", label: "Corpo Científico"},
    { link: "/dicionario", label: "Dicionário"},
    { link: "/fontes", label: "Fontes"},
    { link: "/bibliografia", label: "Bibliografia"},
    { link: "/noticias", label: "Notícias"}
];