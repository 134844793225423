import React, { useState, useEffect } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { DataView } from "primereact/dataview";
import { DictionaryFilters } from "./Internal/DictionaryFilters";
import { Link } from "react-router-dom";
import Banner from "components/Banner/Banner";
import styles from "./Dictionary.module.scss";

function Dictionary() {
    const crumbs = [
        { label: "Início", link :"/" }
    ]
    
    let pageSize = 10;                       // set number of page links to display in paginator

    const [ data, setData ] = useState();
    const [ first, setFirst] = useState(0);
    const [ defaultPageSize, setDefaultPageSize ] = useState(pageSize ?? 10);
    const [ showPagination, setShowPagination ] = useState(true);
    
    const [ filters, setFilters ] = useState();

    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);
    
    async function loadData(first = 0, pageSize = defaultPageSize) {
        let url = `${apiroutes.novels}/withcharacters`;

        const firstAndCount = (pageSize !== undefined) ? `first=${first}&count=${pageSize}` : "";
        const filterby = (filters) ? getRequestFilters(filters) : "";
        
        if (url && (firstAndCount || filterby)) {
            url = `${url}${url.indexOf("?") === -1 ? "?" : "&"}${firstAndCount}${filterby}`;
        }

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            let data = await response.json();

            setData(data);
            setShowPagination(data.totalCount > pageSize);
        }
        else {            
            setFirst(0);
            setData({ first: 0, count: 0, totalCount: 0, items: [] });
            setShowPagination(false);
        }
    }
    
    function handlePageChange(ev) {
        // set new page values
        setFirst(ev.first);
        setDefaultPageSize(ev.rows);
        
        // reload datatable
        loadData(ev.first, ev.rows);
        // scroll to top of list
        document.getElementsByTagName("html")[0].scroll({top: 355, left: 0, behavior: "smooth" });
    }

    function getRequestFilters(filters) {
        var requestFilters = "";
    
        Object.keys(filters).map((filter) => {
            if (filters[filter]) {
                var filterValue = encodeURIComponent(filters[filter]);
                return requestFilters = requestFilters + `&filters=${filter} equ ${filterValue}`;
            }
            else return "";
        });
    
        return requestFilters;
    }

    const renderListItem = (item) => {
        return (item &&
            <div className="col-12 px-0">
                <div className={styles["dictionary__list-item"]}>
                    <div className={styles["dictionary__item-detail"]}>
                        <Link to={`/dicionario/${item.id}`}><h2 className={styles["dictionary__item-title"]}>{item.title}</h2></Link>
                        <dl className={styles["dictionary__item-subtitle"]}>
                            {item.startYear && <>
                                <dt>Ano publicação:</dt>
                                <dd>{item.startYear}{item.endYear && `-${item.endYear}`}</dd>
                            </>}
                            {item.authors.length > 0 && <>
                                <dt>Autor(es):</dt>
                                <dd>{item.authors.map((author, idx) => { return <span key={idx}>{author.fullName}</span> })}</dd>
                            </>}
                            {item.characters.length > 0 && <>
                                <dt>Personagem(ns):</dt>
                                <dd>{item.characters.map((character, idx) => {
                                    if (idx === 0) return <span key={idx}>{character.name}</span>
                                    else return <span key={idx}>, {character.name}</span>
                                })}</dd>
                            </>}
                        </dl>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Banner title="Dicionário" breadcrumbs={crumbs} />
            <section id="dictionary" className="pt-5">
                <DictionaryFilters onChange={setFilters} />
                <div className="container-md">
                    <div className="row whitebox">
                        <div className="col-lg-12">
                            <div className={styles["dictionary"]}>
                                {data && 
                                    <DataView value={data.items} layout={"list"} lazy itemTemplate={renderListItem}
                                        paginator={showPagination} first={first} rows={defaultPageSize} onPage={handlePageChange}
                                        totalRecords={data.totalCount}
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dictionary