import createBrowserHistory from "../init/history";
import { showErrorMessage } from "admin/components/AppMessage/AppMessage";
import { BehaviorSubject } from "rxjs";

/**
 * Sends an authenticated HTTP request with application/json content.
 * @param  {url} url The web address.
 * @param  {string} [method = "GET"] The HTTP method.
 * @param  {object} [body = null] The data to send in the body as json.
 * @param  {boolean} [parseErrors = true] Parse and show response errors.
 * @param  {boolean} [lockScreen = false] Lock screen while fetching.
 * @return Promise with the response.
 */
 export const authFetchAsync = async (url, method = "GET", body = null, parseErrors = true, isScreenToLock = false) => {
    const user = new BehaviorSubject(JSON.parse(localStorage.getItem("currentUser")));
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: method,
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": user.value ? `Bearer ${user.value.token}` : ""
            }),
            body: (body != null) ? JSON.stringify(body) : undefined
        }).then(function (response) {
            if (parseErrors && !response.ok) {
                parseResponseErrorAsync(response).then((msg) => {
                    console.log("An error occured:", msg);
                });
            }
            resolve(response);
        });
    });
};

/**
 * Sends a raw authenticated HTTP request.
 * @param  {url} url The web address.
 * @param  {string} [method = "GET"] The HTTP method.
 * @param  {object} [body = null] The data to send in the body.
 * @param  {boolean} [parseErrors = true] Parse and show response errors.
 * @param  {boolean} [lockScreen = false] Lock screen while fetching.
 * @return Promise with the response.
 */
 export const authFetchRawAsync = async (url, method = "GET", body = null, parseErrors = true, isScreenToLock = false) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: method,
            // headers: new Headers({
            //     "Authorization": "Bearer " + token,
            //     "Galaxy-Language": state.userSettings.language ? state.userSettings.language : null,
            // }),
            body: (body != null) ? body : undefined
        }).then(function (response) {
            if (parseErrors && !response.ok) {
                parseResponseErrorAsync(response).then((msg) => {
                    showErrorMessage(msg);
                });
            }
            
            resolve(response);
        });
    });
};

/**
 * Download a file with an authenticated HTTP request.
 * @param  {url} url The web address.
 * @return Promise.
 */
 export const authDownloadAsync = async (url) => {

    return new Promise((resolve, reject) => {

        authFetchRawAsync(url).then(async (response) => {
            if (!response.ok) { reject(response); return }
            let blob = await response.blob();
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            let contentDisposition = response.headers.get("Content-Disposition");
            let fileName = "download";
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^;"]+)[";]/);
                if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            resolve();
        });
        //.catch((error) => { });
    });
};

export function parseResponseErrorAsync(response) {

    if (response.status === 403){
        createBrowserHistory.replace("/access");
        return new Promise((resolve, reject) => {
            reject({ok: false})
        });
    }

    return new Promise((resolve, reject) => {
        response.text().then(content => {
            console.error(`${response.statusText} ${content}`);

            let json = tryParseJSON(content);            
            if (!json) {
                // Don't show HTML as an error message
                if (content.startsWith("<!DOCTYPE")) content = null;
                resolve(content || response.statusText);
            }

            // json example: {"error": [ "msg1", "msg2"], "error2": [ "msg3" ] };
            let result = "";
            for (var key in json) {
                // skip loop if the property is from prototype
                // eslint-disable-next-line no-prototype-builtins
                if (!json.hasOwnProperty(key)) continue;

                const obj = json[key];
                if (obj.constructor !== Array) continue;

                for (const s of obj) {
                    result = s.errorMessage ? s.errorMessage : (result ? `${result}\n${s}` : s);
                }
            }
            resolve(result);
        });
    });
}

function tryParseJSON(jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { console.error("authFetch tryParseJSON error", e) }

    return false;
}