import React, { useState, useEffect } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import Banner from "components/Banner/Banner";
import styles from "./Project.module.scss";

function Project() {
    const crumbs = [
        { label: "Início", link :"/" },
        { label: "Projeto" }
    ]

    const [ data, setData ] = useState();
    useEffect(() => { loadData(); }, []);
    
    async function loadData() {
        let response = await authFetchAsync(`${apiroutes.contents}/1`, "GET", null, false);

        if (response.ok) {
            setData(await response.json());
        }
    }

    return (data ?
        <>
            <Banner title={data.title} breadcrumbs={crumbs} image="bg003.jpg" />
            <section className={styles["project"]}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 whitebox">
                            <div className="widget heading_space text-center text-md-left">
                                <div dangerouslySetInnerHTML={{__html: data.body}} className={styles["project__body"]}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
        : null
    );
}

export default Project;