import React from "react";
import classNames from "classnames";
import styles from "./Footer.module.scss";

function Footer() {
    return (<>
        <section id="stayconnect" className={classNames([styles["contact-form"]],"bglight position-relative")}>
            <div className="container">
                <div className="contactus-wrapp">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <form className="getin_form wow fadeInUp" data-wow-delay="400ms" onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12" id="result"></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="name" className="d-none"></label>
                                            <input className="form-control" type="text" placeholder="Nome:" required id="name" name="name" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email" className="d-none"></label>
                                            <input className="form-control" type="email" placeholder="Email:" required id="email" name="email" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message" className="d-none"></label>
                                            <input className="form-control" type="text" placeholder="Mensagem:" required id="message" name="message" />
                                        </div>
                                        <button type="submit" className={classNames([styles["send-button"]],"button gradient-btn")} id="submit_btn">Enviar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="heading-title wow fadeInUp text-center text-md-left" data-wow-delay="300ms">
                                <h2 className="bottom20">_Contacto_</h2>
                                <p>
                                    Faculdade de Letras da Universidade de Lisboa<br/>
                                    Alameda da Universidade<br/>
                                    1600-214 Lisboa, Portugal<br/>
                                    <a className="d-block" href="mailto:investigadores@portuguesesdepapel.com">investigadores@portuguesesdepapel.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer id="site-footer" className={classNames([styles["footer"]], "bgdark padding_top")}>
            <div className="container">
                <div className="row">
                    <div className={classNames([styles["partners"]], "col")}>
                        <img src="/images/logoClepul.png" alt="clepul" />
                        <img src="/images/logoFLUL-white.png" alt="flul" />
                        <img src="/images/logoFCT-white.png" alt="fct" />
                    </div>
                </div>
                <div className="row">
                    <div className={classNames([styles["disclaimer"]], "col")}>
                        <div>
                            <span style={{color:"#d8132f"}}>© Linha de investigação Brasil: literatura, memória e diálogos com Portugal (GI 3 / CLEPUL)</span><br />
                            Atividade financiada por Fundos Nacionais através da FCT – Fundação para a Ciência e a Tecnologia, I.P. no âmbito dos Projetos «UID/ELT/00077/2013», «UID/ELT/00077/2019» e «UIDB/000777/2020»
                        </div>
                        <div>Powered by <a href="http://www.switx.me" target="_blank" rel="noopener noreferrer">Switx</a></div>
                    </div>
                </div>
            </div>
        </footer>
    </>);
}

export default Footer;