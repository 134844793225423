import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { Button } from "primereact/button";
import { Galleria } from "primereact/galleria";
import { Link } from "react-router-dom";
import AttachmentTypeEnum from "services/constants/AttachmentTypeEnum";
import Banner from "components/Banner/Banner";
import styles from "./Article.module.scss";

function Article() {
    const { id } = useParams();
    const [ isMounted, setIsMounted ] = useState(false);
    const [ article, setArticle ] = useState();

    const galeria = useRef();

    useEffect(() => {
        setIsMounted(true);

        if (isMounted) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted]);
    
    async function loadData() {
        let url = `${apiroutes.contents}/${id}`;

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            let data = await response.json();

            setArticle(data);
        }
        else {            
            console.log("it wasn't possible to load article");
        }
    }

    const galeriaItemTemplate = (item) => {
        return <img src={`/images/attachments/${item.fileName}`} alt={item.description} style={{ width: "50%", display: "block" }} />;
    }
        
    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 5
        },
        {
            breakpoint: "768px",
            numVisible: 3
        },
        {
            breakpoint: "560px",
            numVisible: 1
        }
    ];

    return (
        <> {article &&
            <>
                <Banner title={article.title} />
                <section className={styles["article"]}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 whitebox">
                                <div className="widget heading_space text-center text-md-left">
                                    <div dangerouslySetInnerHTML={{__html: article.body}} className={styles["article__body"]}/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="image">
                                    <img src={`/images/news/${article.pictureName}`} alt="" className="img-responsive"/>
                                </div>
                                {article.attachments &&
                                    article.attachments.some(attachment => attachment.attachmentTypeId === AttachmentTypeEnum.Image) &&
                                    <>
                                        <Galleria ref={galeria} value={article.attachments.filter(attachment => attachment.attachmentTypeId === AttachmentTypeEnum.Image)}
                                            responsiveOptions={responsiveOptions} numVisible={7} style={{ maxWidth: "850px" }} baseZIndex={2000}
                                                circular fullScreen showItemNavigators showThumbnails={false} item={galeriaItemTemplate} />
                                        <Button label="Galeria" className="button gradient-btn mt-3" icon="far fa-fw fa-image mr-1" onClick={() => galeria.current.show()} />
                                    </>}
                                {article.attachments &&
                                    article.attachments.some(attachment => attachment.attachmentTypeId === AttachmentTypeEnum.File) &&
                                    <>
                                        {article.attachments.filter(attachment => attachment.attachmentTypeId === AttachmentTypeEnum.File).map(file => {
                                            return <Link to={`/files/${file.fileName}`} target="_blank" download>
                                                <Button label={`Download ${file.name}`} className="button gradient-btn mt-3" icon="far fa-fw fa-download mr-1" />
                                            </Link>
                                        })}
                                    </>}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        }
        </>
    );
}

export default Article