import React, { useState, useEffect } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Link } from "react-router-dom";
import { NovelTypeEnum } from "services/constants/NovelTypeEnum";
import { PersonTypeEnum } from "services/constants/PersonTypeEnum";
import { Sidebar } from "components/Sidebar/Sidebar";
import classNames from "classnames";
import styles from "./TeamMember.module.scss";

function TemMember({ id, visible, setVisible }) {
    
    const [ data, setData ] = useState();

    useEffect(() => {
        if (id) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    
    async function loadData() {
        let url = `${apiroutes.persons}/${id}/detail`;

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            setData(await response.json());
        }
    }

    return (<>
        {data &&
            <Sidebar visible={visible} setVisible={setVisible} className="double">
                <div className={styles["team-member__wrapper"]} >
                    <h2>_{data.name.replaceAll(" ", "_")}_</h2>
                    {data.pictureName &&
                        <div className={styles["team-member__photo"]} >
                            <img alt="" src={`/images/persons/${data.pictureName}`}/>
                        </div>}
                    <dl>
                        <dt>Instituição:</dt>
                        <dd>{data.institution}</dd>
                    </dl>
                    <dl>
                        <dt>Habilitações Literárias:</dt>
                        <dd dangerouslySetInnerHTML={{__html: data.description}} />
                    </dl>
                    <dl>
                        <dt>Áreas de Especialização:</dt>
                        <dd dangerouslySetInnerHTML={{__html: data.specialities}} />
                    </dl>
                    <dl>
                        <dt>Link para o Curriculum vitae público:</dt>
                        <dd><a href={`${data.url}`} rel="noopener noreferrer" target="_blank">{data.url}</a></dd>
                    </dl>
                    {(data.personType === PersonTypeEnum.Direccao || data.personType === PersonTypeEnum.ComCientifica) && data.analysis.length > 0 &&
                        <dl>
                            <dt>Pareceres:</dt>
                            <dd>{data.analysis.length}</dd>
                        </dl>}
                    {data.novels.length > 0 &&
                        <>
                            <p><strong>Colaboração no Dicionário: </strong></p>    
                            <ul className={styles["team-member__novels"]}>
                                {data.novels.map((novel, novelIdx) => {
                                    return <li key={novelIdx} className="clearfix">
                                        <Avatar icon={`far fa-fw ${novel.numberOfCharacters > 0 ? "fa-users" : "fa-users-slash" }`} className={classNames({ "p-overlay-badge": novel.numberOfCharacters > 0 })} shape="square" >
                                            {novel.numberOfCharacters > 0 && <Badge value={novel.numberOfCharacters} />}
                                        </Avatar>
                                        {/* <span className={classNames([styles["team-member__novel-icon"]], { "no-entrance": novel.numberOfCharacters === 0})}>
                                            <i className={classNames("far", "fa-fw", { "fa-users": novel.numberOfCharacters > 0 }, { "fa-users-slash": novel.numberOfCharacters === 0 })} />
                                        </span> */}
                                        <div className={styles["team-member__novel"]}>
                                            <span className={styles["team-member__novel-year"]}>{novel.startYear}:</span>
                                            <span className={styles["team-member__novel-title"]}>
                                                {novel.numberOfCharacters > 0 ?
                                                        <Link to={`/dicionario/${novel.id}`}>{novel.title}</Link>
                                                    :
                                                        novel.entranceTypeId === NovelTypeEnum.Novel ? <i>{novel.title}</i> : novel.title
                                                }
                                            </span>
                                            <span className={styles["team-member__novel-info"]}>
                                                - {novel.authorNamesStringified}
                                            </span>
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </>}
                </div>
            </Sidebar>}
    </>)
}

export default TemMember