import React from "react";
import { Switch } from "react-router-dom";
import Route from "components/Route/Route";

import Home from "pages/Home/Home.js";
import Project from "pages/Project/Project.js";
import Team from "pages/Team/Team.js";
import Dictionary from "pages/Dictionary/Dictionary.js";
import Novel from "pages/Novel/Novel.js";
import Sources from "pages/Sources/Sources.js";
import News from "pages/News/News.js";
import Article from "pages/News/Article.js";
import Biblio from "pages/Biblio/Biblio.js";

const AppRoutes = (
	<Switch>
		<Route exact path="/" component={Home} />
		<Route exact path="/projeto" component={Project} />
		<Route exact path="/corpo-cientifico" component={Team} />
		<Route exact path="/dicionario" component={Dictionary} />
		<Route exact path="/dicionario/:id" component={Novel} />
		<Route exact path="/fontes" component={Sources} />
		<Route exact path="/noticias" component={News} />
		<Route exact path="/noticias/:id" component={Article} />
		<Route exact path="/bibliografia" component={Biblio} />
	</Switch>
);

export default AppRoutes;