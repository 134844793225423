
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import Loading from "components/Loading/Loading";
import history from "services/init/history";
import registerServiceWorker from "./registerServiceWorker";
import store from "services/store/store";

// Static imports
import App from "./App";
// Dynamic imports
//const App = React.lazy(() => import("./App"));
const AppAdminWrapper = React.lazy(() => import("./admin/AppWrapper"));

const renderApp = () => {
	ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
    </Provider>,
	document.getElementById("root")
	);
	
  registerServiceWorker();
}

const renderAppAdmin = () => {
	ReactDOM.render(
		<Suspense fallback={Loading}>
			<Provider store={store}>
				<Router history={history}>
					<AppAdminWrapper />
				</Router>
			</Provider>
		</Suspense>,
		document.getElementById("root")
	);
}

if (window.location.pathname.startsWith("/admin") || window.location.pathname.startsWith("/login"))
	renderAppAdmin()
else 
	renderApp();