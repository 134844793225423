import React from "react";
import { Sidebar as PrimeReactSidebar } from "primereact/sidebar";
import classNames from "classnames";
import Title from "components/Title/Title.js";
import "./Sidebar.scss";

export function Sidebar(props) {
    return <>
        <PrimeReactSidebar visible={props.visible} baseZIndex={1000} onHide={() => { if (props.setVisible) props.setVisible(false) }}
            className={classNames("sidebar", props.className)} position="right" >
            {props.visible &&
                <>
                    {props.title &&
                        <div className="sidebar-header">
                            <Title>{props.title}</Title>
                        </div>}
                    <div className="sidebar-body">
                        {props.children}
                    </div>
                </>}
        </PrimeReactSidebar>
        <div className={classNames("sidebar-overlay", { "visible": props.visible })}></div>
    </>
}