import React, { useState, useEffect } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import classNames from "classnames";
import Banner from "components/Banner/Banner";
import PersonTypeEnum from "services/constants/PersonTypeEnum";
import TeamMember from "./Internal/TeamMember";
import styles from "./Team.module.scss";

function Team() {

    const crumbs = [
        { label: "Início", link :"/" }
    ]

    const [ data, setData ] = useState();
    const [ memberId, setMemberId ] = useState();
    const [ showMemberDetail, setShowMemberDetail ] = useState(false);

    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    async function loadData() {
        let url = `${apiroutes.persons}`;

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            setData(await response.json());
        }
    }

    function handleClick(id) {
        setMemberId(id);
        setShowMemberDetail(true);
    }

    return (
        <>
            <Banner title={"Corpo Científico"} breadcrumbs={crumbs} />
            <section className={styles["team"]}>
                <div className={classNames([styles["team-container"]], "container")}>
                    <div className="row">
                        <div className="col-md-12 text-justify heading_space animated wow fadeIn d-table mx-auto" data-wow-delay="300ms">
                        <TabView>
                            <TabPanel header="_Direção_">
                                {data &&
                                    <ul>
                                        {data.items.filter(member => member.personTypeId ===  PersonTypeEnum.Board).map((member, idx) => {
                                            return <li key={idx}>
                                                <Button label={member.name} className={classNames("button-backgroundless", "mb-1")} onClick={() => handleClick(member.id)} /> <span>({member.institution})</span>
                                            </li>
                                        })}
                                    </ul>}
                            </TabPanel>
                            <TabPanel header="_Comissão_Científica_">
                                {data &&
                                    <ul>
                                        {data.items.filter(member => member.personTypeId === PersonTypeEnum.ScientificCommittee).map((member, idx) => {
                                            return <li key={idx}>
                                                <Button label={member.name} className={classNames("button-backgroundless", "mb-1")} onClick={() => handleClick(member.id)} /> <span>({member.institution})</span>
                                            </li>
                                        })}
                                    </ul>}
                            </TabPanel>
                            <TabPanel header="_Investigadores/Pareceristas_">
                                {data &&
                                    <ul>
                                        {data.items.filter(member => member.personTypeId === PersonTypeEnum.Researchers).map((member, idx) => {
                                            return <li key={idx}>
                                                <Button label={member.name} className={classNames("button-backgroundless", "mb-1")} onClick={() => handleClick(member.id)} /> <span>({member.institution})</span>
                                            </li>
                                        })}
                                    </ul>}
                            </TabPanel>
                        </TabView>
                        </div>
                    </div>
                </div>
            </section>
            <TeamMember id={memberId} visible={showMemberDetail} setVisible={setShowMemberDetail} />
        </>
    )
}

export default Team;