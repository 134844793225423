import React from "react";
import AppRoutes from "./AppRoutes.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import "layout/css/all.min.css"
import "layout/css/animate.min.css"
// import "layout/css/owl.carousel.min.css"
// import "layout/css/jquery.fancybox.min.css"
// import "layout/css/tooltipster.min.css"
// import "layout/css/cubeportfolio.min.css"
// import "layout/css/revolution/navigation.css"
// import "layout/css/revolution/settings.css"

import "layout/sass/ppapel.scss";

const App = (props) => {
    return (
        <>
            <Header />
            <div className="layout-content">
                {AppRoutes}
            </div>
            <Footer />
        </>
    );
}

export default App;