import React from "react";

export default function Loading() {
    return (
        <div className="loader">
            <div className="loader-inner">
                <div className="cssload-loader"></div>
            </div>
        </div>
    );
}