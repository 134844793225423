import React, { useState, useEffect } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { DataView } from "primereact/dataview";
import { NovelTypeEnum } from "services/constants/NovelTypeEnum";
import { SourcesFilters } from "./Internal/SourcesFilters";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Banner from "components/Banner/Banner";
import styles from "./Sources.module.scss";

function Sources() {
    const crumbs = [
        { label: "Início", link :"/" }
    ]
    
    const [ data, setData ] = useState();
    const [ option, setOption] = useState();
    const [ letter, setLetter] = useState();

    useEffect(() => {
        if (option !== undefined && letter) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option, letter]);
    
    async function loadData() {
        let url = `${apiroutes.novels}/byauthor`;

        const optionBy = (option !== undefined) ? `&option=${option}` : "";
        const filterby = (letter) ? `&letter=${letter}` : "";
        
        if (url && (optionBy || filterby)) {
            url = `${url}${url.indexOf("?") === -1 ? "?" : "&"}${optionBy}${filterby}`;
        }

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            let data = await response.json();

            setData(data);
        }
        else {            
            setData({ first: 0, count: 0, totalCount: 0, items: [] });
        }
    }

    const renderListItem = (item) => {
        return (item &&
            <div className={classNames(styles["sources__list-item"], "col-12 px-0")}>
                <Avatar icon={`far fa-fw ${item.numberOfCharacters > 0 ? "fa-users" : "fa-users-slash" }`} className={classNames({ "p-overlay-badge": item.numberOfCharacters > 0 })} shape="square" >
                    {item.numberOfCharacters > 0 && <Badge value={item.numberOfCharacters} />}
                </Avatar>
                <div className={styles["source"]}>
                    <div className="row ml-0">
                        <span className={styles["source__author"]}>{`${item.author.lastName.toUpperCase()}, ${item.author.name}`} </span>
                        <span className={styles["source__novel-year"]}>({`${item.startYear}${item.endYear ? "-"+ item.endYear: ""}`}) - </span>
                        <span className={styles["source__novel-title"]}>
                            {item.numberOfCharacters > 0 ?
                                    <Link to={`/dicionario/${item.id}`}>{item.entranceTypeId === NovelTypeEnum.Novel ? <i>{item.title}</i> : item.title}</Link>
                                :
                                item.entranceTypeId === NovelTypeEnum.Novel ? <i>{item.title}</i> : item.title
                            }. 
                        </span>
                        {item.city &&
                            <span className={styles["source__novel-editor"]}> {`${item.city}${item.editor ? ": "+ item.editor : ""}`}</span>}
                    </div>
                    <div className="row ml-0">
                        <dl>
                            <dt>Investigador(a):</dt>
                            <dd className={styles["source__person"]}>{item.persons.map((person, idx) => {
                                if (idx > 0) return `, ${person.name}`
                                else return person.name
                            })}</dd>
                        </dl>
                        
                    </div>
                </div>
            </div>
        );
    }

    let sourceOptions = [
        { label: "Com personagens portuguesas", code: true},
        { label: "Sem personagens portuguesas", code: false}
    ]

    return (
        <>
            <Banner title="Fontes" breadcrumbs={crumbs} options={sourceOptions} onOptionChange={(e) => setOption(e.value.code)} />
            <section id="sources" className={styles["sources"]}>
                <SourcesFilters letter={letter} onChange={setLetter}/>
                <div className="container-md">
                    <div className="row whitebox">
                        <div className="col-lg-12">
                            {data && 
                                <DataView value={data.items} layout={"list"} lazy itemTemplate={renderListItem}
                                    // paginator={showPagination} first={first} rows={defaultPageSize} onPage={handlePageChange}
                                    // totalRecords={data.totalCount}
                                    // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                />}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sources