import React from "react";
import { Route as ReactRouter, Redirect } from "react-router-dom";
import { BehaviorSubject } from "rxjs";

function Route(props) {    
    const { component: Component, render, privateRoute = false } = props;
    const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("currentUser")));

    return (
        <ReactRouter path={props.path} render={props => {
            if (privateRoute && !currentUserSubject.value) {
                // user not authenticated so redirect to login page
                return <Redirect to={{ pathname: `/login?from=${props.path}`}} />
                // return <Login pathname={props.path} />
            }
            else {
                // authorized so return component
                return render ? render(props) : <Component {...props} />
            }
        }} />
    )
}

export default Route;