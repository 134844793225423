import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import styles from "./Banner.module.scss";

function Banner(props) {
    const [ option, setOption ] = useState();

    function handleChangeOption(ev) {
        setOption(ev.value);

        props.onOptionChange(ev);
    }

    return (<>
        <section id="main-banner-page" className={classNames([styles["banner"]], "position-relative page-header  section-nav-smooth")}>
            <div className="overlay overlay-dark opacity-1"></div>
        </section>
        <div className={styles["title-wrapper"]}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 whitecolor">
                        <h2 className={classNames([styles["title"]], "float-left")}>_{props.title.replaceAll(" ", "_")}_</h2>
                        {props.options &&
                            <div className={classNames(styles["options"], "top10 bottom10 float-right")}>
                                <Dropdown options={props.options} placeholder="Escolha uma opção" optionLabel={"label"} value={option} onChange={handleChangeOption} />
                            </div>}
                    </div>
                </div>
                {props.subtitle &&
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            {props.subtitle}
                        </div>
                    </div>}
            </div>
        </div>
    </>)
}

export default Banner;