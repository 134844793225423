import React, { useEffect, useState } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { DataView } from "primereact/dataview";
import { Link } from "react-router-dom";
import Banner from "components/Banner/Banner";
import classNames from "classnames";
import ContentTypeEnum from "services/constants/ContentTypeEnum";
import styles from "./Biblio.module.scss";

function Biblio() {
    const [ isMounted, setIsMounted ] = useState(false);
    const [ data, setData ] = useState({ first: 0, count: 0, totalCount: 0, items: [] });

    useEffect(() => {
        setIsMounted(true);

        if (isMounted) loadData();
    }, [isMounted]);
    
    async function loadData() {
        let url = `${apiroutes.contents}/biblio`;

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            let data = await response.json();

            setData(data);
        }
        else {            
            setData({ first: 0, count: 0, totalCount: 0, items: [] });
        }
    }
    

    const renderListItem = (item) => {
        return (item &&
            <div className={classNames(styles["biblio__list-item"], "card")}>
                <div className="card-header">
                    <a className="collapsed card-link darkcolor" data-toggle="collapse" href={`#biblio-${item.id}`}>{item.authorLastName.toUpperCase()}, {item.authorName} ({new Date(item.published).getFullYear()})</a>
                </div>
                <div id={`biblio-${item.id}`} className="collapse" data-parent="#accordion">
                    <div className={classNames(styles["biblio__list-item-body"], "card-body")} dangerouslySetInnerHTML={{__html: item.body}} />
                </div>
            </div>
        );
    }

    return (
        <>
            <Banner title="Bibliografia" />
            <section className={styles["biblio"]}>
                <div className="container-md">
                    <div className="row whitebox">
                        <div className="col-lg-12">
                            {data && 
                                <DataView value={data.items} layout={"list"} itemTemplate={renderListItem} />}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Biblio;