import { createStore, combineReducers } from "redux";
import appMessageReducer from "./reducers/appMessage.js";
import appSettingsReducer from "./reducers/appSettings.js";
import userMenusReducer from "./reducers/userMenus.js";

export const store = createStore(
    combineReducers({
        appMessage: appMessageReducer,
        appSettings: appSettingsReducer,
        userMenus: userMenusReducer
    })
);

export default store;