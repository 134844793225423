import React, { useState, useRef, useEffect } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

function Home() {    
    const [ data, setData ] = useState();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { loadData(); }, []);
    
    async function loadData() {
        let url = `${apiroutes.contents}/home`;

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            setData(await response.json());
        }
    }

    return (
        <>
            <section id="home" className="p-0 light-slider single-slide">
                <h2 className="d-none">hidden</h2>
                <div id="rev_single_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="trax_slider_01">
                    <div id="rev_single" className="rev_slider fullwidthabanner" data-version="5.4.8.1">
                        <ul>
                            <li data-index="rs-layers" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="500"  data-rotate="0"  data-saveperformance="off"  data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                                <div className="overlay overlay-dark opacity-1"></div>
                                <img src="/images/bg001.jpg" data-bgcolor="#e0e0e0" alt="" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="0" className="rev-slidebg" data-no-retina />
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {data &&
                <section id="our-feature" className="single-feature py-5">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 text-justify wow fadeInLeft" data-wow-delay="300ms">
                                <div className="heading-title mb-4">
                                    <h2 className="defaultcolor font-normal">_Projeto_</h2>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: data.description}} />
                                <Link to="/projeto"><Button label="Ver mais" className="button gradient-btn mt-3" icon="far fa-fw fa-eye mr-1" /></Link>
                            </div>
                        </div>
                    </div>
                </section>}
            {data &&
                <section id="bg-counters" className="bg-counters parallax">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-lg-4 col-md-4 col-sm-4 bottom10">
                                <div className="counters whitecolor  top10 bottom10">
                                    <span className="count_nums font-light"><CountUp startOnMount={false} end={data.numberOfResearchers} duration={5} /></span>
                                </div>
                                <h3 className="font-light whitecolor top20">Investigadores</h3>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 bottom10">
                                <div className="counters whitecolor  top10 bottom10">
                                    <span className="count_nums font-light"><CountUp startOnMount={false} end={data.numberOfNovels} duration={5} /></span>
                                </div>
                                <h3 className="font-light whitecolor top20">Obras</h3>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 bottom10">
                                <div className="counters whitecolor top10 bottom10">
                                    <span className="count_nums font-light"><CountUp startOnMount={false} end={data.numberOfCharacters} duration={5} /></span>
                                </div>
                                <h3 className="font-light whitecolor top20">Personagens</h3>
                            </div>
                        </div>
                    </div>
                </section>}
        </>
    );
}

export default Home;