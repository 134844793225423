import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Title.module.scss";

export function Title(props) {

    function renderIcon(iconStyle, colorless) {
        if (!iconStyle || !iconStyle.icon) return null;

        // Titled icons are showed as duotone blurred.
        let css = colorless ?
            classNames("fad", "fa-fw", props.iconStyle.icon) :
            classNames("fad", "fa-fw", props.iconStyle.icon, "icon-blur-duotone");
        return <i className={css} style={{ marginRight: "8px" }} />
    }

    return (
        <h2 className={classNames(styles["title"], !props.colorless && props.iconStyle && props.iconStyle.className ? `${props.iconStyle.className}-color` : null)} >
            {renderIcon(props.iconStyle, props.colorless)}
            {props.children}
            {
                (props.annotation !== undefined && !props.disableAnnotation) && <span className={styles["title-annotation"]}>{props.annotation}</span>
            }
        </h2>
    );
}

export default Title;

Title.propTypes = {
    /** Style to be applied to the title {icon, color} */
    iconStyle: PropTypes.object,
    /** Specifies if color should be omitted */
    colorless: PropTypes.bool,
    /** Annotation text */
    annotation: PropTypes.any
}
