import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { Accordion, AccordionTab } from "primereact/accordion";
import Banner from "components/Banner/Banner";
import styles from "./Novel.module.scss";

function Novel() {

    const crumbs = [
        { label: "Início", link :"/" },
        { label: "Dicionário", link :"/dicionario" },
    ]

    const { id } = useParams();
    const [ data, setData ] = useState();

    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    async function loadData() {
        let url = `${apiroutes.novels}/${id}/detail`;

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            setData(await response.json());
        }
    }

    const renderSubtitle = (data &&
        <div className={styles["novel__subtitle"]}>
            <dl>
                {data.startYear && <>
                    <dt>Ano publicação:</dt>
                    <dd>{data.startYear}{data.endYear && `-${data.endYear}`}</dd>
                </>}
                {data.authors.length > 0 && <>
                    <dt>Autor(a):</dt>
                    <dd>{data.authors.map((author, idx) => { return <span key={idx}>{author.fullName}</span> })}</dd>
                </>}
                <dt>Investigador(a):</dt>
                {data.persons.length > 0 && <>
                    <dd>{data.persons.map((person, idx) => { return <span key={idx}>{person.name}</span> })}</dd>
                </>}
                {data.entryDate && <>
                    <dt>Data Entrada:</dt>
                    <dd>{data.entryDate}</dd>
                </>}
            </dl>
        </div>
    )

    return (<>
        {data && <>
            <Banner title={data.title} subtitle={renderSubtitle} breadcrumbs={crumbs} />
            <section className={styles["novel"]}>
                <div className="container-md">
                    <div className="row whitebox">
                        <div className="col-lg-12">
                            <div className="widget heading_space text-center text-md-left">
                                <div dangerouslySetInnerHTML={{__html: data.description}} className={styles["novel__body"]}/>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div id="accordion">
                                        <div className="card">
                                            <div className="card-header">
                                                <a className="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseCharacters">Personagens</a>
                                            </div>
                                            <div id="collapseCharacters" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    <div className={styles["novel__characters"]}>
                                                        <Accordion>
                                                            {data.characters.map((character, idx) => {
                                                                return <AccordionTab key={idx} header={character.name} className={styles["novel__character-tab"]}>
                                                                    <div className={styles["novel__character-detail"]} dangerouslySetInnerHTML={{__html: character.description}} />
                                                                </AccordionTab>
                                                            })}
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {data.notes &&
                                            <div className="card">
                                                <div className="card-header">
                                                    <a className="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseNotes">Notas</a>
                                                </div>
                                                <div id="collapseNotes" className="collapse" data-parent="#accordion">
                                                    <div className="card-body" dangerouslySetInnerHTML={{__html: data.notes}} />
                                                </div>
                                            </div>}
                                        <div className="card">
                                            <div className="card-header">
                                                <a className="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseSources">Fontes</a>
                                            </div>
                                            <div id="collapseSources" className="collapse" data-parent="#accordion">
                                                <div className="card-body" dangerouslySetInnerHTML={{__html: data.biblio}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>}
    </>)
}

export default Novel;