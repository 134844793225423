import React from "react";
import { Button } from "primereact/button";
import classNames from "classnames";
import styles from "./SourcesFilters.module.scss";

export function SourcesFilters(props) {
    return <div className={classNames([styles["sources__filters"]], "container")}>
        <div className="row">
            <div className="col-md-12 col-lg-12 mb-3">
                <Button className={classNames("button mr-3", { "active": props.letter === "A" })} label={"A"} onClick={() => props.onChange("A")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "B" })} label={"B"} onClick={() => props.onChange("B")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "C" })} label={"C"} onClick={() => props.onChange("C")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "D" })} label={"D"} onClick={() => props.onChange("D")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "E" })} label={"E"} onClick={() => props.onChange("E")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "F" })} label={"F"} onClick={() => props.onChange("F")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "G" })} label={"G"} onClick={() => props.onChange("G")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "H" })} label={"H"} onClick={() => props.onChange("H")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "I" })} label={"I"} onClick={() => props.onChange("I")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "J" })} label={"J"} onClick={() => props.onChange("J")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "K" })} label={"K"} onClick={() => props.onChange("K")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "L" })} label={"L"} onClick={() => props.onChange("L")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "M" })} label={"M"} onClick={() => props.onChange("M")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "N" })} label={"N"} onClick={() => props.onChange("N")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "O" })} label={"O"} onClick={() => props.onChange("O")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "P" })} label={"P"} onClick={() => props.onChange("P")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "Q" })} label={"Q"} onClick={() => props.onChange("Q")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "R" })} label={"R"} onClick={() => props.onChange("R")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "S" })} label={"S"} onClick={() => props.onChange("S")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "T" })} label={"T"} onClick={() => props.onChange("T")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "U" })} label={"U"} onClick={() => props.onChange("U")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "V" })} label={"V"} onClick={() => props.onChange("V")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "W" })} label={"W"} onClick={() => props.onChange("W")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "X" })} label={"X"} onClick={() => props.onChange("X")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "Y" })} label={"Y"} onClick={() => props.onChange("Y")} />
                <Button className={classNames("button mr-3", { "active": props.letter === "Z" })} label={"Z"} onClick={() => props.onChange("Z")} />
            </div>
        </div>
    </div>
}