import React, { useEffect, useState } from "react";
import { apiroutes } from "services/apiroutes/apiroutes";
import { authFetchAsync } from "services/auth/authFetch";
import { DataView } from "primereact/dataview";
import { Link } from "react-router-dom";
import Banner from "components/Banner/Banner";
import classNames from "classnames";
import ContentTypeEnum from "services/constants/ContentTypeEnum";
import styles from "./News.module.scss";

function News() {
    const [ isMounted, setIsMounted ] = useState(false);
    const [ data, setData ] = useState({ first: 0, count: 0, totalCount: 0, items: [] });

    useEffect(() => {
        setIsMounted(true);

        if (isMounted) loadData();
    }, [isMounted]);
    
    async function loadData() {
        let url = `${apiroutes.contents}?typeId=${ContentTypeEnum.Article}`;

        let response = await authFetchAsync(url, "GET", null, false);

        if (response.ok) {
            let data = await response.json();

            setData(data);
        }
        else {            
            setData({ first: 0, count: 0, totalCount: 0, items: [] });
        }
    }
    
    const renderListItem = (item) => {
        return (item &&
            <div className={classNames(styles["news__list-item"], "col-md-4 px-0 mr-3")}>
                <div className={styles["article"]}>
                    <div className={styles["article__image"]}>
                        <img src={`/images/news/${item.pictureName}`} alt=""/>
                    </div>
                    <Link to={`/noticias/${item.id}`}><h2 className={styles["article__title"]}>{item.title}</h2></Link>
                </div>
            </div>
        );
    }

    return (
        <>
            <Banner title="Notícias" />
            <section id="news" className={styles["news"]}>
                <div className="container-md">
                    <div className="row whitebox">
                        <div className="col-lg-12">
                            {data &&
                                <DataView value={data.items} layout={"list"} lazy itemTemplate={renderListItem} />}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default News